/**======================================================================
=========================================================================
Template Name: Light Able - Bootstrap Admin Template
Author: phoenixcoded
Support: https://phoenixcoded.authordesk.app
File: style.css

1.  Generic-class css
2.  Custom css
3.  Sidebar css
4.  header css
5.  footer css
6.  Common layout css
7.  Compact layout css
8.  Horizontal layout css
9.  Tab layout css
10. Widget css 
11. Avtar css
12. Badge css
13. Button css
14. Dropdown css
15. Card css
16. Progress css
17. Table css
18. Tabs css
19. Form css
20. Authentication css
21. Maintaince css
22. Pages css
23. Icon layouts css
24. User card css
25. Application css
26. Sweetalert css
27. Lightbox css
28. Notification css
29. Modal css
30. Rangeslider css
31. Daterange css
32. Tagsinput css
33. Slider css
34. Switches css
35. Data Tables css
36. Customizer css

=========================================================================
=================================================================================== */

// main framework
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/variables-dark';

@import 'settings/color-variables';
@import 'settings/theme-variables';
@import 'settings/bootstrap-variables';

@import 'node_modules/bootstrap/scss/maps';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/images';
@import 'node_modules/bootstrap/scss/containers';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/transitions';
@import 'node_modules/bootstrap/scss/dropdown';
@import 'node_modules/bootstrap/scss/button-group';
@import 'node_modules/bootstrap/scss/nav';
@import 'node_modules/bootstrap/scss/navbar';
@import 'node_modules/bootstrap/scss/card';
@import 'node_modules/bootstrap/scss/accordion';
@import 'node_modules/bootstrap/scss/breadcrumb';
@import 'node_modules/bootstrap/scss/pagination';
@import 'node_modules/bootstrap/scss/badge';
@import 'node_modules/bootstrap/scss/alert';
@import 'node_modules/bootstrap/scss/progress';
@import 'node_modules/bootstrap/scss/list-group';
@import 'node_modules/bootstrap/scss/close';
@import 'node_modules/bootstrap/scss/toasts';
@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/tooltip';
@import 'node_modules/bootstrap/scss/popover';
@import 'node_modules/bootstrap/scss/carousel';
@import 'node_modules/bootstrap/scss/spinners';
@import 'node_modules/bootstrap/scss/offcanvas';
@import 'node_modules/bootstrap/scss/placeholders';
@import 'node_modules/bootstrap/scss/helpers';

[data-pc-direction='ltr'] {
  @import 'node_modules/bootstrap/scss/utilities';
  @import 'node_modules/bootstrap/scss/utilities/api';
}

[data-pc-direction='rtl'] {
  @import 'themes/layouts/style-rtl-utility';
  @import 'node_modules/bootstrap/scss/utilities/api';
}

// main framework
@import 'themes/generic';
@import 'themes/general';

// Layouts
@import 'themes/layouts/pc-sidebar';
@import 'themes/layouts/pc-header';
@import 'themes/layouts/pc-footer';
@import 'themes/layouts/pc-common';
@import 'themes/layouts/pc-compact';
@import 'themes/layouts/pc-horizontal';
@import 'themes/layouts/pc-tab';

// widget
@import 'themes/components/widget';

// Components
@import 'themes/components/avtar';
@import 'themes/components/badge';
@import 'themes/components/button';
@import 'themes/components/dropdown';
@import 'themes/components/card';
@import 'themes/components/progress';
@import 'themes/components/table';
@import 'themes/components/tabs';
@import 'themes/components/form';

// pages
@import 'themes/pages/authentication';
@import 'themes/pages/maintaince';
@import 'themes/pages/pages';
@import 'themes/pages/icon-pages';
@import 'themes/pages/user';
@import 'themes/pages/application';

// custom pluginss
@import 'themes/plugins/sweatalert';
@import 'themes/plugins/lightbox';
@import 'themes/plugins/notification';
@import 'themes/plugins/modal';
@import 'themes/plugins/rangeslider';
@import 'themes/plugins/daterange';
@import 'themes/plugins/tags-input';
@import 'themes/plugins/slider';
@import 'themes/plugins/switches';
@import 'themes/plugins/data-tables';

@import 'themes/layouts/customizer';
