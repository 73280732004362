// ============================
//    3. Sidebar css start
// ============================

.pc-sidebar {
  background: var(--pc-sidebar-background);
  width: $sidebar-width;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1026;
  overflow: hidden;
  box-shadow: var(--pc-sidebar-shadow);
  border-right: var(--pc-sidebar-border);

  .navbar-wrapper {
    width: $sidebar-width;
    background: inherit;
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  .pc-caption {
    color: var(--pc-sidebar-caption-color);
    display: block;
    padding: 16px 23px 8px !important;
    text-transform: capitalize;
    position: relative;
    line-height: 1.34;

    &:first-child {
      padding-top: 10px !important;
      &::after {
        display: none;
      }
    }
    font: {
      size: 12px;
      weight: 500;
    }
    svg,
    i {
      display: none;
    }
    label {
      margin-bottom: 0;
    }
    span:not(.badge) {
      display: block;
      color: #67758a;

      font: {
        size: 14px;
        weight: 500;
      }
    }
  }
  .pc-micon {
    margin-right: 15px;
    height: 24px;
    width: 24px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;

    i {
      font-size: 20px;
      vertical-align: middle;

      &.material-icons-two-tone {
        font-size: 22px;
        display: inline-block;
      }
    }

    > svg {
      width: 22px;
      height: 22px;
      display: inline-block;
    }
  }
  .pc-link {
    display: block;
    padding: 14px 20px;
    color: var(--pc-sidebar-color);
    position: relative;
    font: {
      size: 14px;
      weight: 400;
    }
    &:focus,
    &.active,
    &:hover {
      text-decoration: none;
    }
  }

  .pc-navbar > .pc-item {
    margin: 0 10px;
    > .pc-link {
      &::after {
        content: '';
        border-radius: var(--bs-border-radius);
        position: absolute;
        top: 2px;
        right: 2px;
        left: 2px;
        bottom: 2px;
        opacity: 0.1;
      }
    }
    &.active {
      > .pc-link {
        font-weight: 500;
        color: var(--pc-sidebar-active-color);
        &:after {
          background: var(--pc-sidebar-active-color);
        }
      }
    }
    &:hover:not(.active) {
      > .pc-link {
        &:after {
          background: var(--pc-sidebar-color);
        }
      }
    }
    .pc-submenu {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 30px;
        height: 100%;
        width: 1px;
        border-left: 1px solid var(--pc-sidebar-submenu-border-color);
      }
      .pc-item {
        > .pc-link {
          &:after {
            content: '';
            position: absolute;
            top: 20px;
            left: 45px;
            height: 5px;
            width: 5px;
            border-radius: 50%;
            background: var(--pc-sidebar-submenu-border-color);
          }
        }
        &.pc-trigger,
        &.active {
          > .pc-link {
            font-weight: 500;
            color: var(--pc-sidebar-active-color);
          }
        }
        &.pc-trigger,
        &.active,
        &:focus,
        &:hover {
          > .pc-link {
            &:after {
              background: var(--pc-sidebar-active-color);
            }
          }
        }
      }
    }
    .pc-submenu {
      &:after {
        left: 30px;
      }
      .pc-link {
        padding: 12px 30px 12px 60px;
        &:after {
          left: 28px;
        }
      }
      .pc-submenu {
        &:after {
          left: 46px;
        }
        .pc-link {
          padding: 12px 30px 12px 80px;
          &:after {
            left: 62px;
          }
        }

        .pc-submenu {
          &:after {
            left: 63px;
          }
          .pc-link {
            padding: 12px 30px 12px 95px;
            &:after {
              left: 79px;
            }
          }
        }
      }
    }
  }
  .pc-item {
    &.disabled {
      a {
        cursor: not-allowed;
        user-select: none;
      }
    }
  }
  .pc-arrow {
    float: right;
    display: inline-block;
    transition: all 0.2s ease-in-out;

    > svg {
      width: 14px;
      height: 14px;
    }
  }
  .pc-badge {
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 1;
    float: right;
    background: var(--bs-primary);
  }
  .m-header {
    height: $header-height;
    display: flex;
    align-items: center;
    padding: 16px 24px;

    .logo-sm {
      display: none;
    }
  }
  .navbar-content {
    position: relative;
    height: calc(100vh - #{$header-height + 80px});
    padding: 10px 0;
  }

  .card {
    box-shadow: none;
    border: none;
    margin: 15px;
    &.pc-user-card {
      background: var(--pc-sidebar-background);
      margin: 0;
      box-shadow: none;
      border-radius: 0px;
      border-top: 1px solid var(--pc-sidebar-submenu-border-color);
      .card-body {
        padding: 15px;
        h6{
          color: var(--pc-sidebar-color);
        }
      }
      .dropdown-menu {
        width: calc(#{$sidebar-width} - 30px);
        padding: 0;
        overflow: hidden;
        background: var(--pc-sidebar-background);
        ul {
          align-items: center;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          li {
            position: relative;
            flex: 50%;
            &::before {
              content: '';
              position: absolute;
              bottom: -2px;
              left: 0;
              height: 1px;
              width: 100%;
              opacity: 0.3;
              border-bottom: 1px solid var(--pc-sidebar-submenu-border-color);
            }
            &:nth-child(even) {
              &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 1px;
                opacity: 0.3;
                border-left: 1px solid var(--pc-sidebar-submenu-border-color);
              }
            }
            &:nth-last-child(0),
            &:nth-last-child(1) {
              &::before {
                display: none;
              }
            }
          }
        }
      }
      .pc-user-links {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: var(--pc-sidebar-color);
        padding: 12px 4px;
        border-radius: var(--bs-border-radius);
        margin: 8px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: var(--pc-sidebar-active-color);
          opacity: 0;
        }
        i {
          font-size: 24px;
          margin-bottom: 4px;
        }
        &:focus,
        &:hover {
          color: var(--pc-sidebar-active-color);
          &::after {
            opacity: 0.1;
          }
        }
      }
    }
    &.nav-action-card {
      .card-body {
        background-size: 200%;
        background-position: center;
      }
    }
  }
  .pc-hasmenu {
    &:not(.pc-trigger) {
      > .pc-submenu {
        display: none;
      }
    }
    &.pc-trigger {
      > .pc-submenu {
        display: block;
      }

      > .pc-link {
        > .pc-arrow {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.pc-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.15);
}
[data-pc-sidebar-caption='false'] {
  .pc-sidebar .pc-caption {
    display: none;
  }
}
@media (min-width: 1025px) {
  .navbar-overlay {
    .pc-sidebar {
      &.pc-over-menu-active {
        .navbar-wrapper {
          position: relative;
          z-index: 5;
        }
      }

      &:not(.pc-over-menu-active) {
        left: -#{$sidebar-width};
        box-shadow: none;
      }
    }
  }
  .pc-sidebar {
    transition: width 0.2s ease;
    ~ .pc-header {
      transition: left 0.2s ease;
    }
    ~ .pc-footer,
    ~ .pc-container {
      transition: margin-left 0.2s ease;
    }
    &.pc-sidebar-hide {
      width: 0;
      --pc-sidebar-border: none;
      ~ .pc-header {
        left: 0;
      }
      ~ .pc-footer,
      ~ .pc-container {
        margin-left: 0px;
      }
    }
  }
  .pc-header .pc-h-item.pc-sidebar-popup {
    display: none;
  }
}
@media (max-width: 1024px) {
  .pc-header .pc-h-item.pc-sidebar-collapse {
    display: none;
  }
  .pc-sidebar {
    left: -#{$sidebar-width};
    box-shadow: none;
    // background: var(--bs-body-bg);
    top: 0;
    transition: all 0.2s ease-in-out;
    &.mob-sidebar-active {
      left: 0;

      .navbar-wrapper {
        position: relative;
        z-index: 5;
        background: inherit;
      }
    }
  }
}
.layout-2{
  .pc-sidebar{
    .pc-navbar{
      > .pc-item{
        margin: 0;
        
        > .pc-link{
          border-bottom: 1px solid rgba(255,255,255,.05);
          margin: 0;
          padding-top: 23px;
          padding-bottom: 23px;
          &:after{
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 0;
          }
        }
        &.pc-hasmenu.pc-trigger,
        &.active{
          background-color:rgba(var(--pc-sidebar-color-rgb), 0.08);
          > .pc-link{
            &:after{
              background: transparent;
            }
          }
        }
        .pc-submenu{
          padding-top: 10px;
          padding-bottom: 10px;
          &:after{
            opacity: .3;
          }
        }
      }
    }
  }
}
// ============================
//    3. Sidebar css end
// ============================
